import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const CareersReasonsSection: React.FC<any> = () => {
  const cutoutImage = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "careers_reasons_bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div id="CareersReasons">
      <div className="split5050">
        <h2>Top Reasons to Work at Fins</h2>
        <p>
          Working at Fins Car Wash is more than just washing cars, it’s an
          opportunity for growth, success and lifelong friendship.
        </p>
        <div className="reason">
          <div className="icon">
            <img src="/img/icon_heart.png" />
          </div>
          <div>
            <h3>WE CARE</h3>
            At AAA we care for our customers and our team members. We are a
            brand that has been known and trusted for over 100 years.
          </div>
        </div>

        <div className="reason">
          <div className="icon">
            <img src="/img/icon_brain.png" />
          </div>
          <div>
            <h3>WE TEACH</h3>
            You will gain lifelong customer service skills, learn to work with a
            team and accomplish goals together.
          </div>
        </div>

        <div className="reason">
          <div className="icon">
            <img src="/img/icon_circular_arrows.png" />
          </div>
          <div>
            <h3>WE'RE FLEXIBLE</h3>
            Flexible schedules that give you the freedom to work hours that best
            suit you.
          </div>
        </div>
        <div className="reason">
          <div className="icon">
            <img src="/img/icon_exclamation.png" />
          </div>
          <div>
            <h3>WE'RE FUN</h3>
            You should feel great about your job.
          </div>
        </div>
        <div className="reason">
          <div className="icon">
            <img src="/img/icon_money.png" />
          </div>
          <div>
            <h3>WE COMPETE</h3>
            Fins offers competitive pay for all team members.
          </div>
        </div>
        <div className="reason">
          <div className="icon">
            <img src="/img/icon_thumbs_up.png" />
          </div>
          <div>
            <h3>WE'RE FRIENDLY</h3>
            Have fun with a hardworking and diverse team.
          </div>
        </div>
      </div>
      <div className="split5050" style={{ justifyContent: 'flex-end' }}>
        <Img
          fluid={cutoutImage.placeholderImage.childImageSharp.fluid}
          className="Careers-content-image"
        />
      </div>
    </div>
  );
};

export default CareersReasonsSection;
