import React, { useEffect } from 'react';
import { useMedia } from '../utils/hooks';
import SEO from '../components/seo';
import Footer from '../components/footer';
import FinsWashIcon from '../components/icons/finswash';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Button from '../components/button';

import CareersReasonsSection from '../sections/careers-reasons';
import CareersPositionsSection from '../sections/careers-positions';

import '../styles/promo-page.css';
import '../styles/careers-page.css';
import CareersInterviewTipsSection from '../sections/careers-interview-tips';
import CareersBenefitsSection from '../sections/careers-benefits';

function noop() {
  return;
}

const CareersPage: React.FC<any> = () => {
  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     window.location.replace(`https://finsautowash.com/careers`);
  //   }
  // }, []);

  const iconSize = useMedia(
    ['(min-width: 376px)', '(min-width: 275px)', '(min-width: 0px)'],
    [
      { width: '206', height: '40' },
      { width: '249.18', height: '47.54' },
      { width: '166.12', height: '31.69' }
    ],
    { width: '206', height: '40' }
  );

  const cutoutImage = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "working_at_fins_car_wash_v2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div id="PromoTemplate" className="Promo-container">
      <SEO title="Fins Car Wash" />
      <svg
        className="PromoTemplate__background-img"
        viewBox="0 0 10 10"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient id="gradient">
            <stop offset="1%" stopColor="#649A30" />
            <stop offset="80%" stopColor="#FDF1A1" />
          </linearGradient>
        </defs>
        <polygon fill="url(#gradient)" points="0 0 10 0 0 10" />
      </svg>
      <div className="Promo-container__header">
        <FinsWashIcon color="#16478E" {...iconSize} />
      </div>

      <div id="CareersContainer">
        <h1>Experience a Fintastic Career</h1>
      </div>

      <div className="careers-container-full">
        <div className="split5050">
          <Img
            fluid={cutoutImage.placeholderImage.childImageSharp.fluid}
            className="Careers-content-image"
          />
        </div>
        <div className="split5050 content">
          <h2>Working at Fins Car Wash</h2>
          <p>
            Fins Car Wash is proudly created by AAA. <br />
            <br />
            Learn what it’s like to work for the most trusted brand in
            automotive.
          </p>

          <a href="https://whistleexpresscarwash.com/careers/">
            <Button className="ctaFJobs" text="SEARCH JOBS" />
          </a>
        </div>
      </div>

      <CareersReasonsSection />
      <CareersPositionsSection />
      <CareersBenefitsSection />
      <CareersInterviewTipsSection />

      <Footer items={[]} onNavItemSelected={noop} newsletter={false} />
    </div>
  );
};

export default CareersPage;
