import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Button from '../../components/button';

const CareersInterviewTipsSection: React.FC<any> = () => {
  return (
    <div id="CareersInterviewTips">
      <div className="split5050 content">
        <h2>Interview Tips</h2>
        <p>
          <strong>Face-to-face team member interviews</strong> are typically 30
          minutes to an hour depending on the position for which you are
          interviewing.
        </p>
        <p>
          <strong>Be prepared to discuss</strong> your education and/or work
          experience, including why you want to be a Fins team member.
        </p>
        <p>
          <strong>Dress for success!</strong> A professional first impression is
          important whether you’re looking for your first job or have prior work
          experience.
        </p>
        <a href="https://whistleexpresscarwash.com/careers/">
          <Button className="ctaFJobs" text="APPLY NOW" />
        </a>
      </div>
      <div className="split5050 finn-background"></div>
    </div>
  );
};

export default CareersInterviewTipsSection;
