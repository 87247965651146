import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Button from '../../components/button';
import Card from '../../components/card';

const CareersBenefitsSection: React.FC<any> = () => {
  return (
    <div id="CareersBenefits">
      <div className="background-image-container">
        <h2>FINS Benefits</h2>

        <div className="cards-container">
          <div className="card">
            <h3>Flexible Schedule</h3>
            <p>
              Whether you’re a student, parent, working multiple jobs, or simply
              looking for your first job, Fins gives you the freedom to work
              hours that best suit your life (days, evening, nights, weekends).
            </p>
          </div>
          <div className="card">
            <h3>Competitive Pay + Growth Opportunities</h3>
            <p>
              Feel good knowing that you scored a job that offers impressive
              starting pay with plenty of room for advancement.
            </p>
          </div>
          <div className="card">
            <h3>Training + Development</h3>
            <p>
              Of course you’ll learn how to wash a car, but you’ll also gain
              valuable experience while on the job, such as customer service
              skills and how to be a team player.
            </p>
          </div>
          <div className="card">
            <h3>Rewards</h3>
            <p>
              Hard work and a job well done will always be recognized at Fins
              Car Wash with annual awards and team member appreciation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersBenefitsSection;
