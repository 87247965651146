import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Button from '../../components/button';

const CareersPositionsSection: React.FC<any> = () => {
  return (
    <div id="CareersPositions">
      <div className="background-image-container">
        <h2>FINS POSITIONS</h2>

        <div className="content">
          <h3>Team Members</h3>
          <p>
            As part of the Fins family, you’ll be a member of our FINTASTIC
            team, working together to provide customers with an excellent wash
            that keeps them coming back. Must be 16 years old to apply.
          </p>

          <h3>Managers</h3>
          <p>
            As the team leader, managers are responsible for ensuring that
            customers receive a FINTASTIC wash and customer service. Managers
            will teach, assist and motivate team members to excel in every
            aspect of their position.
          </p>

          <h3>Opportunities for Advancements</h3>
          <p>
            Build a rewarding career that offers the opportunity for growth and
            success.
          </p>
          <br />
          <br />
          <a href="https://whistleexpresscarwash.com/careers/">
            <Button className="white ctaFJobs" text="APPLY NOW" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CareersPositionsSection;
